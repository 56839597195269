
<template lang='pug'>

  //*** POP UP : SEND to WASH
  div
    vs-popup(classContent='popup-example' title='รายละเอียดการส่งซัก/ซ่อม' :active.sync="popPanel")
      //*** หัวข้อ
      //- vs-row.row-form(vs-w='12' style="background-color:rgb(248, 248, 248)" )
      //-   vs-col.form-label.py-4.px-6(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
      //-     h4(style="font-size:1.7rem;") ข้อมูลเพิ่มร้านซัก/ซ่อม
      vs-row.row-form(vs-w='12' style='margin-top:20px;')
        vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='4' vs-sm='4' vs-xs='4')
          h4.py-2(v-if='wnfOrder.header.orderStatus==="กำลังซักซ่อม"' style='text-align:center; width:100%; height:100%;  border-radius:5px;' class='px-1 label-btn-yellow') {{wnfOrder.header.orderStatus}}
          h4.py-2(v-else-if='wnfOrder.header.orderStatus==="เสร็จแล้ว"' style='text-align:center; width:100%; height:100%;  border-radius:5px;' class='px-1 label-btn-green') {{wnfOrder.header.orderStatus}}
          h4.py-2(v-else-if='wnfOrder.header.orderStatus==="ไม่ส่งซัก"' style='text-align:center; width:100%; height:100%;  border-radius:5px;' class='px-1 label-btn-red') {{wnfOrder.header.orderStatus}}
          h4.py-2(v-else style='text-align:center; width:100%; height:100%;  border-radius:5px;' class='px-1 label-btn-grey') ไม่ระบุ

        vs-col.px-4(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2')
          i.material-icons(style='cursor:pointer; font-size:28px;vertical-align:middle;', @click="getRedirectToPrintOrder(wnfOrder.header.wnfOrderId)") print


      //*** เส้นแบ่ง
      //- vs-divider.mb-0(v-if="sendingAction===1"  )

      .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%; padding: 20px 0px;')

          //*** ร้านซัก/ซ่อม
          vs-row.row-form(vs-w='12' style='margin-top:20px;' v-if="wnfOrder.header.orderStatus==='กำลังซักซ่อม' || wnfOrder.header.orderStatus==='เสร็จแล้ว'")
            vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2') ร้านซัก/ซ่อม
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 9999 !important;') {{wnfShopName(wnfShop)}}

          //*** ช่วงเวลาส่งซัก
          vs-row(vs-w='12' style='margin-top:20px;' v-if="wnfOrder.header.orderStatus==='กำลังซักซ่อม' || wnfOrder.header.orderStatus==='เสร็จแล้ว'")
            vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2' style='z-index : 10 !important;') ช่วงเวลา
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 9995 !important;') {{format_ddmmyyy(wnfOrder.header.sendingDate)}} ~ {{format_ddmmyyy(wnfOrder.header.receivingDate)}}

          //*** หมายเหตุ
          vs-row.row-form(vs-w='12' style='margin-top:20px;'  v-if="wnfOrder.header.orderStatus==='กำลังซักซ่อม' || wnfOrder.header.orderStatus==='เสร็จแล้ว'")
            vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2' style='z-index : 10 !important;') หมายเหตุ
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 10 !important;') {{(wnfOrder.header.remark)}}

          //*** เส้นแบ่ง
          vs-divider.mb-0(v-if="wnfOrder.header.orderStatus==='กำลังซักซ่อม' || wnfOrder.header.orderStatus==='เสร็จแล้ว'")

          //*** สาขา
          vs-row.row-form(vs-w='12')
            vs-col.form-label.py-6.px-6(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
              h4(style="font-size:1.2rem;") {{getCurrentBranch.branchName}}



          //*** ROW OF SENDING TO WASH
          //*** POPUP
          vs-row(style='display: flex;width: 100%;padding: 20px 0px;margin-bottom: 30px;border-bottom: 1px solid #e4e4e4;' vs-w='12' v-for="(sitem, sindex) in wnfOrder.itemList" :key="sindex")
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='3' vs-sm='3' vs-xs='3' vs-offset='1')
              div(style='vertical-align: text-top;')
                img.responsive.rounded(:src='getFullpath(sitem.mainImage)' alt='user-upload' style='width:100px;')
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='5' vs-sm='5' vs-xs='5')
              .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%;')

                //*** ข้อมูลสินค้า
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:1.2rem;color: #4a90e2;") {{sitem.name}}
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") ประเภท : {{getCategoryName(sitem.category)}}

                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") สี : {{getColorName(sitem.color)}}

                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") ไซส์ : {{getSizeName(sitem.size)}}

                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") รหัส : {{sitem.code}}

            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='2' vs-sm='2' vs-xs='2')
              .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%;')
                //*** จำนวน
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='center' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:1.2rem;") จำนวน
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='center' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:1.2rem;") {{sitem.reservedQuantity}}



</template>

<script>

import { BEmbed } from 'bootstrap-vue'
import Multiselect from "vue-multiselect";
import Prism from "vue-prism-component";
import vSelect from "vue-select";
// import HotelDatePicker from "vue-hotel-datepicker";
import Datepicker from "vuejs-datepicker";
import shapeFormat from "../../mixins/shapeFormat.js";
// import ProductService from "./Services/ProductService";
import Selection1 from "./Components/Selection1";
// import UpdateStockSidebar from './Components/UpdateStockSidebar.vue'

// const productService = new ProductService();


export default {
  props: {
    panelPopup: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [shapeFormat],
  data() {
    return {

     name: "",
     address: "",
     phone: "",
     description: "",
     status: true,

     thePanel: false,
      //*** begin : WASHING SHOP list
      washingShopPanel: false,
      washingShop: "",
      washingShopList: [],
      infoNote: "",
      washingDate:{},
      displayItems: [],
      sendingItems:[],
      sendingReq:{},
      sendingAction: 0,
      wnfShop: {}
      // 1: ส่งซัก, 2: รับชุดซักกลับ, 3: ไม่ส่งซัก

      //*** end : WASHING SHOP list


    };
  },


  async created() {
    // await this.$store.dispatch(
    //   "productGroup/getAllInfo"
    // );
  },

  async mounted() {

    console.log("this.wnfOrder >>> ",this.wnfOrder);
    var shopRes = null;
    try {
      shopRes = await this.$http.post(
        this.$store.state.apiURL + "/api/v2/orderheader/clean-fix/getWnfShop",
        {},
        this.$store.state.auth.apiHeader
      );


      this.washingShopList = shopRes.data.itemList;
      this.$store.commit("washingList/SET_WNFSHOP",this.washingShopList);


      var list = this.washingShopList.filter(x => x.wnfShopId === this.wnfOrder.header.wnfShopId);
      if(list.length > 0){
        this.wnfShop = list[0];

      }else{
        this.wnfShop = {};
      }

    } catch (error) {
      shopRes = null;

    }

  },

  computed: {
    categories(){
      return this.$store.getters["productGroup/categories"];
    },
    textures(){
      return this.$store.getters["productGroup/textures"];
    },
    colors(){
      return this.$store.getters["productGroup/colors"];
    },
    sizes(){
      return this.$store.getters["productGroup/sizes"];
    },
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    popPanel :{
      set(){
        this.closePanel();
      },
      get(){
        // console.log("this.$store.getters['wnfShop/popPanel']", this.$store.getters["wnfShop/popPanel"]);
        return this.$store.getters["wnfOrder/displayPopup"];
      }

    },
    wnfOrder(){
      return this.$store.getters["wnfOrder/anOrder"];
    }

  },

  watch: {
    description(){
      // this.remaining(100,this.description) < 0
      // console.log('this.description >>>>>>> ',this.description.length);
      if(this.remaining(100,this.description) < 0 ){
        this.description.substring(0, 99)
      }

    }
    // popPanel : function(){
    //   console.log("!!! WATCH popPanel ",this.popPanel);
    // }
  }
,
  methods:{
    getRedirectToPrintOrder(id) {
      window.open("/pages/wnf-order/"+id, "_blank");
  },
    getFullpath(mainImage) {
      if (
        mainImage === undefined ||
        mainImage === null ||
        mainImage === "" ||
        mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + mainImage;
      }
    },
    getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    getColorName(codename) {
      console.log("this.colors >>> ",this.colors);
      var c = this.colors.find(x => x.code === codename);
      console.log("codename >>> ",codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },
    getSizeName(codename) {
      var c = this.sizes.find(x => x.code === codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },

    wnfShopName(wnfShop){
      if(wnfShop === null || wnfShop === undefined ){
        return "ไม่ระบุ";
      }else{
        return wnfShop.wnfShopName;
      }
    },
    closePanel(){
      this.$store.commit("wnfOrder/POP_PANEL",false);
      this.name = "";
      this.address = "";
      this.description = "";
      this.phone = "";
      this.additionalInfo = "";
      // this.$store.commit("wnfShop/ADD_OR_UPDATE",0);
      this.$store.commit("wnfShop/UPDATE_ITEM",{});
      // this.panelPopup = false;
    }
  },
  components: {
    Multiselect,
    BEmbed,
    Prism,
    Datepicker,
    // VueRangedatePicker,
    // VueHotelDatepicker,
    "v-select": vSelect,
    // HotelDatePicker,
    Selection1

  },

};
</script>

<style>

.vs-dropdown--menu{
  width: 170px;
}

.multiselect__content-wrapper .multiselect-leave-active{
  z-index: 99999 !important;
}

/* .vs__search{
  display: none !important;
} */
.clear-btn:hover{
   text-decoration: underline;
}
/* .vs-pagination--nav {
  display: none;
} */

.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.checkqueuelist {
  margin-top: 0;
}
.input-date {
  width: 100% !important;
}

.dropdown-toggle .clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none;
}
.selected-tag {
  font-size: 12px;
}

/* VUE HOTEL DATEPICKUP */
.previous-arrow .offset-2 .disabled {
  display: block !important;
}

/* .calendar {
  position: relative !important;
} */

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
  z-index: 999 !important;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

.zoomimage {
  width: 400px;
}
.datepicker__tooltip {
  display: none !important;
}

.demo-alignment {
  vertical-align: middle;
}

li {
  margin: 0 !important;
}

@media only screen and (max-width: 578px) {
  #main-background {
    width: 100% !important;
    padding: 0 !important;
  }
  #controller-panel {
    width: 91% !important;
  }
}
</style>
