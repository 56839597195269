
<template lang='pug'>

  #main-background
    clean-fix-popup-display-order(:key='displayPopup', :panelPopup='panelDisplay' )

    //- filter-clean-fix-sidebar(:key='filterCleanFixKey', :isSidebarActive='filterDataSidebar', @closeSidebar='toggleFilterDataSidebar', @returnSearchParameter='SearchParameterValue', :data='filterSidebarData')
    .vx-card.px-4
      //*** HEADER
      vs-row(vs-w='12')
        vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='10' vs-sm='10' vs-xs='10' style='margin-top:20px;')
        vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='2' vs-sm='2' vs-xs='12')


      //*** ADDING BUTTON
      //- vs-row(vs-type='flex' vs-justify='flex-end' vs-w='12')
      //-   vs-col(vs-type='flex' vs-justify='flex-end' vs-align='center' vs-lg='3' vs-sm='3' vs-xs='3')
      //-     vs-button.btn-danger(style='background-color:rgb(126, 211, 33) !important; width:150px; margin-right: 20px;', @click="add(1, null)" , :disabled='!btnActive' ) เพิ่มร้านซัก/ซ่อม

      //*** SEARCH TEXT

      vs-row.pb-6(vs-type='flex' vs-justify='flex-end' vs-w='12')
        vs-col.pt-2.px-2(vs-type='flex ' vs-justify='flex-start' vs-align='center' vs-lg='2' vs-sm='2' vs-xs='12') ทั้งหมด {{theDisplay.itemNumber}} รายการ
        vs-col.pb-8.px-2.clear-btn(style='cursor:pointer; font-size: 0.8rem; color:red;' vs-type='flex' vs-justify='flex-end' vs-align='flex-end' vs-lg='2' vs-sm='2' vs-xs='6')
          span(@click='clearFilter()') เคลียร์ข้อมูลการค้นหา
        vs-col.pb-4.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-end' vs-lg='3' vs-sm='3' vs-xs='12')
          vs-input.mt-5.w-full(v-model='searchText' placeholder="ค้นหาโดยเลขออเดอร์ + Enter" @keyup.enter="searching()")
        vs-col.pb-4.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-end' vs-lg='3' vs-sm='3' vs-xs='12')
          //*** status
          multiselect(
            v-model="orderStatus",
            :options="orderStatusOption",
          )
          span(slot="noResult").
            ไม่พบข้อมูล

        //*** PAGE SIZE
        vs-col.pb-4.form-label.px-2(vs-type='flex' vs-justify='flex-end' vs-align='center' vs-lg='2' vs-sm='2' vs-xs='4')
          v-select.pt-4(style='width: 70%;' v-model='pageitemSelected' :options='pageitemOptions' :select='pageitemSelected')

      //*** HEADER
      //*** PAGINATION
      vs-row(vs-type='flex' vs-justify='flex-end' vs-w='12')
        vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='12' vs-sm='12' vs-xs='12')
          vs-pagination(style='display: block !important;' :total='pageNumber' v-model='currentx' goto)


      vs-table( class="p-4" :sst='true' @change-page='handleChangePage'  style='z-index:1;' :data='displayItems' )
        //*** TABLE HEADER
        template(style='z-index:1 !important;' slot='thead')
          //- vs-th(flex-type='flex' flex-justify='center')
          //-   feather-icon(v-if='selectStatus===0' v-model='selectAll' @click='selectedAll($event)' icon='SquareIcon' svgclasses='w-4 h-4 stroke-current text-danger' :badge='newnumber')
          //-   feather-icon(v-else-if='selectStatus===1' @click='selectedAll($event)' icon='CheckSquareIcon' svgClasses='stroke-current text-primary' :badge='newnumber')
          //-   feather-icon(v-else-if='selectStatus===2' @click='selectedAll($event)' icon='MinusSquareIcon' svgClasses='stroke-current  text-primary' :badge='newnumber')
          //- vs-th
          //- vs-th
          vs-th
          vs-th
          vs-th

        //*** TABLE BODY
        template(style='z-index:1 !important;' slot-scope='{data}')
          vs-tr(:data='tr'  v-for="(tr, indextr) in data"  :key="indextr" )
            //*** !!! SELECTION !!!
            //- vs-td
            //-   feather-icon(:data-id='tr.productItemId' v-if='data[indextr].selected===true' @click='addSelectedList(tr, $event)' icon='CheckSquareIcon' svgClasses='stroke-current text-primary')
            //-   feather-icon(:data-id='tr.productItemId' v-else @click='addSelectedList(tr, $event)' icon='SquareIcon' svgClasses='stroke-current')

            //*** !!! ชื่อร้านซักซ่อม !!!
            //- vs-td(style="width:30%;")
            //-   span(style='color: #2372CE; font-weight:bold; font-size: 1.2rem;') {{tr.wnfShopName}}

            //*** !!! ข้อมูล WNF ORDER !!!
            vs-td(style=' width:80%;')
              .grid-layout-container.alignment-block
                vs-row.pb-2(vs-w='12')
                  vs-col.px-2( vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='4' vs-sm='4' vs-xs='12' style='color: #2372CE; font-weight:bold; font-size: 1.2rem;' )
                    span(@click="showDetail(tr)") {{tr.orderNumber}}
                  vs-col.px-2(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='4' vs-sm='4' vs-xs='12'  )
                    div.py-2(v-if='tr.orderStatus==="กำลังซักซ่อม"' style='text-align:center; width:100%; height:100%;  border-radius:5px;' class='px-1 label-btn-yellow') {{displayStatus(tr.orderStatus)}}
                    div.py-2(v-else-if='tr.orderStatus==="เสร็จแล้ว"' style='text-align:center; width:100%; height:100%;  border-radius:5px;' class='px-1 label-btn-green') {{tr.orderStatus}}
                    div.py-2(v-else-if='tr.orderStatus==="ไม่ส่งซัก"' style='text-align:center; width:100%; height:100%;  border-radius:5px;' class='px-1 label-btn-red') {{displayStatus(tr.orderStatus)}}
                    div.py-2(v-else style='text-align:center; width:100%; height:100%;  border-radius:5px;' class='px-1 label-btn-grey') ไม่ระบุ

                vs-row.pb-4(vs-w='12')
                  vs-col.px-2(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='4' vs-sm='4' vs-xs='12' style='font-size: 1.0rem;')
                    i(style='font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px;' class='material-icons iconhover') store_mall_directory
                    span {{getNameWnfShop(tr.wnfShopId)}}
                  vs-col.px-2( v-if="tr.orderStatus==='ไม่ส่งซัก'" vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='8' vs-sm='8' vs-xs='12' style='font-size: 1.0rem;')
                    i(style='font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; ' class='material-icons iconhover') calendar_today
                    span วันที่ส่ง-คืน : ไม่ระบุ

                  vs-col.px-2( v-else vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='8' vs-sm='8' vs-xs='12' style='font-size: 1.0rem;')
                    i(style='font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; ' class='material-icons iconhover') calendar_today
                    span วันที่ส่ง-คืน : {{format_ddmmyyy(tr.sendingDate)}} ~ {{format_ddmmyyy(tr.receivingDate)}}


            //*** สถานะ switch
            vs-td(style="width:10%;")
              .grid-layout-container.alignment-block
                vs-row(vs-w='12')
                  vs-col.px-2(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='12' vs-sm='12' vs-xs='12'  ) {{formatDate(tr.updateDateTime)}}
                vs-row(vs-w='12')
                  vs-col.px-2(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='12' vs-sm='12' vs-xs='12') {{tr.updateBy}}



            //*** !!!  การจัดการ !!!
            vs-td(style="width:10%;")
              vs-dropdown.cursor-pointer(vs-trigger-click vs-custom-content='' )
                .px-2.pt-1.aui-button(vs-type='flex' vs-align='center' style=' border-radius:5px;  ')
                  span
                    i.material-icons  more_horiz
                  //***


                //- vs-dropdown-menu( v-if="tr.orderDetailStatus=== null || tr.orderDetailStatus === undefined || tr.orderDetailStatus === 'คืนชุดแล้ว'")
                vs-dropdown-menu
                  //- //*** อัพเดต
                  //- vs-dropdown-item( @click="add(2, tr)")
                  //-   span.flex.items-center
                  //-     //*** <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  //-     span เสร็จสิ้น

                  //*** แสดงรายละเอียด
                  vs-dropdown-item(@click="showDetail(tr)")
                    span.flex.items-center
                      //*** <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                      span แสดงรายละเอียด

                  //*** พิมพ์
                  vs-dropdown-item(@click="getRedirectToPrintOrder(tr.wnfOrderId)")
                    span.flex.items-center
                      //*** <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                      span พิมพ์




        //*** PAGINATION
        .py-10
          vs-pagination(style='display: block !important;' :total='pageNumber' v-model='currentx' goto)
        br




</template>

<script>

import { BEmbed } from 'bootstrap-vue'
import Multiselect from "vue-multiselect";
import Prism from "vue-prism-component";
import vSelect from "vue-select";
// import HotelDatePicker from "vue-hotel-datepicker";
import Datepicker from "vuejs-datepicker";
import shapeFormat from "../../mixins/shapeFormat.js";
// import ProductService from "./Services/ProductService";
import { setTimeout } from "timers";
import Selection1 from "./Components/Selection1";
// import UpdateStockSidebar from './Components/UpdateStockSidebar.vue'
import FilterCleanFixSidebar from './Components/FilterCleanFixSidebar.vue'
import CleanFixShopPanel from "./CleanFixShopPanel.vue"
import CleanFixPopupDisplayOrder from "./CleanFixPopupDisplayOrder.vue"

// const productService = new ProductService();


export default {
  mixins: [shapeFormat],
  data() {
    return {

      orderStatus: "-- โปรดเลือกข้อมูล --",
      orderStatusOption:[],
      searchText: "",
      //*** ADDING UPDATE POPUP
      displayPopup: 0,
      panelDisplay: false,

      //*** SIDEBAR
      filterCleanFixKey: 0,
      itemind: 0,
      updateStockKey: 0,
      filterStockKey: 0,
      productGroup: {},
      addNewDataSidebar: false,
      filterDataSidebar:false,
      sidebarData: {},
      filterSidebarData:{},

      //*** begin : washing list
      washPanel: false,
      washingShop: "",
      washingShopList: [],
      infoNote: "",
      washingDate:{},
      displayItems: [],
      sendingItems:[],
      sendingReq:{},
      sendingAction: 0, // 1: ส่งซัก, 2: รับชุดซักกลับ, 3: ไม่ส่งซัก

      //*** end : washing list



      newnumber: 0,
      theSelected: [],
      currentx: 1,
      pageNumber: 1,
      headerConfig: {},
      calendarData: {},

      calendarCheckq: {},
      calendarBorrow: {},
      branchrad: "",
      borValid: 0,
      checkRes: [],
      colorSelected: "",
      sizeSelected: "",
      borrowReq: [],
      borrowBranchId: "",
      branchRadio: [],
      otherBranches: [],
      firstOtherBranch: {},
      thisBranch: {},
      dlist: [],
      itemsInBranch: [],
      currentBranch: "", // BRANCH ID
      otherBranch: [],
      startingDateValue: new Date(),
      endingDateValue: null,
      theCheckIn: new Date(),
      theCheckOut: null,
      zoomPopupActive: false,
      lastPage: 0,
      pageitemSelected: 16,
      pageitemOptions: [8, 16, 24, 32, 40, 48, 56, 64],
      removeId: "",
      removePopupActive: false,
      removeIndex: -1,
      removeCode: "",
      currentUser: "guest",
      // allBranchCheckbox: true,
      btnActive: true,
      panelId: "",
      categorySelected : "",
      categoryOption: [],
      categories: [],
      colors:[],
      sizes:[],
      code3Digit: "000",
      loadedlist: null,
      anewCheckIn: "2019-04-01",
      checkInDate: null,
      checkOutDate: null,
      checkq: "",
      tryselect: "เมืองทองธานี",
      maximumBorrowedItemAmount: 0,
      borrowedItemAmount: 0,
      selectedBranch: "เลือกสาขา",
      branchOptions: ["เมืองทองธานี", "ราชพฤกษ์"],
      selectedDate: {
        start: "",
        end: ""
      },
      remainingItems: 0,
      currentAmountInstock: 0,
      assignItems: [],
      assignItemPanelActive: false,
      indexToAssign: 0,
      borrowPanelActive: false,
      borrowList: [],
      blist: [],
      checkqueuePanelActive: false,
      checkqueueList: [],
      startDate: undefined,

      instart: undefined,
      inend: undefined,
      startPoint: "2019-05-01",
      endPoint: "2019-05-03",
      checkqueueItem: {
        itemImage: "http://localhost:8088/images/item/defaultimage.png",
        // itemImage:
        //   "https://www.google.com/url?sa=i&source=images&cd=&cad=rja&uact=8&ved=2ahUKEwirvNWq2NLiAhWeiXAKHRt0Bb0QjRx6BAgBEAU&url=%2Furl%3Fsa%3Di%26source%3Dimages%26cd%3D%26ved%3D%26url%3Dhttps%253A%252F%252Fwww.allure.com%252Fstory%252Ffashion-nova-plus-size-controversy%26psig%3DAOvVaw1PNvitmeBB7YkiDCzMkVuq%26ust%3D1559836059922993&psig=AOvVaw1PNvitmeBB7YkiDCzMkVuq&ust=1559836059922993",
        itemName: "Item Name",
        AmountInStock: 0,
        rentalPrice: 0,
        bail: 0,
        itemCategory: "ไม่ระบุ",
        itemColor: "ไม่ระบุ",
        updateDate: "2019-04-23",
        rentalStat: 0,
        itemStatus: false
      },
      rentalQueue: [
        {
          customerName: "คุณเบน",
          startDate: "2019-04-23",
          endDate: "2019-04-25"
        },
        {
          customerName: "คุณจอน",
          startDate: "2019-05-12",
          endDate: "2019-05-15"
        }
      ],

      itemsInStock: [],

      branchCheckbox: ["ลาดพร้าว", "เมืองทองธานี"],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],

      statusToReturn: "คืนชุดแล้ว",
      statusToReturnOption: [
        { text: "คืนชุดแล้ว", value: "คืนชุดแล้ว" },
        { text: "ยกเลิก", value: "ยกเลิก" }
      ],
      popupActive: false,
      searchDate: "",
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: "เงินสด",
      payment_option: [
        { text: "เงินสด", value: "เงินสด" },
        { text: "โอนเงิน", value: "โอนเงิน" },
        { text: "Alipay/wechat", value: "Alipay/wechat" },
        { text: "บัตรเครดิต", value: "บัตรเครดิต" }
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: []
    };
  },
  computed: {
      //  *** BEGIN : 2020-12-12 : Search By branches
    getCurrentPackage(){

        return this.$store.getters["auth/currentPackage"];
    },

     //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission(){
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    },
     //  *** BEGIN : 2020-11-28 : Search By branches
    pdlist(){

      return this.$store.getters["wnfOrder/selected"];
    },
    //  *** BEGIN : 2021-05-18 : Search By branches
    searchParam(){

      return this.$store.getters["wnfOrder/searchParameters"];
    },
    basket: {
      set(){
        this.displayItems = this.$store.getters["wnfOrder/basket"];
      },
      get(){
        return this.$store.getters["wnfOrder/basket"];
      }


    },
    theDisplay(){
      return this.$store.getters["wnfOrder/display"];
    },




    allBranchCheckbox: {
      get: function() {
        var all = true;

        if (this.branchCheckbox.length === this.branches.length) {
          all = true;
        } else {
          all = false;
        }

        return all;
      },
      set: function() {}
    }

  },

  async created() {


    // *** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    let params = {
          // // page (required)
          "page": "1",
          // // pageSize (required)
          "pageSize": this.pageitemSelected,
          // // search Text
          // "searchText": "",
          // branch ID (required)
          "branchId" : this.getCurrentBranch.branchId//"452971b-0e27-4b1c-a9a1-5f46d7c3eaa7"
        //   "sendingStartDate" : "",
        //   "sendingEndDate" : "",
        //   "receivingStartDate" : "",
        //   "receivingEndDate" : "",
        //   "wnfShopIds" : "",
        //   "orderDetailStatus" : "",
        //   "colors" : "R"
        //   ,"size" : "S"
      }

      var shopRes = null;
      try {
      shopRes = await this.$http.post(
        this.$store.state.apiURL + "/api/v2/orderheader/clean-fix/getWnfShop",
        {},
        this.$store.state.auth.apiHeader
      );

      this.$store.commit("washingList/SET_WNFSHOP",this.washingShopList);
      this.washingShopList = shopRes.data.itemList;

      //*** ORDER STATUS */

    } catch (error) {
      shopRes = null;

    }


    await this.$store.dispatch("wnfOrder/fetchList",params);

    this.orderStatusOption = ["-- โปรดเลือกข้อมูล --" ,"กำลังดำเนินการ", "เสร็จแล้ว", "ไม่ดำเนินการ"];


  await this.$store.dispatch(
      "productGroup/getAllInfo"
    );


    //*** Load WASHING SHOP INFO */



    this.displayItems = this.cloneArray(this.basket);

    this.pageNumber = this.theDisplay.pageNumber;
    // *** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // this.orders = await this.loadAllOrderShare();
    // this.dlist = await this.loadAllDetailShare();
    this.thisBranch =  this.getCurrentBranch //this.getBranch(this.$store.state.auth.mybranchid);
    // this.branchrad = this.getCurrentBranch.branchId;




    // MODIFIED <<<<<<<<<<<<<<<<<
    // this.checkSelected();
  },

  async mounted() {
    // this.checkSelectedStatus();
  },

  methods: {

clearFilter(){
  this.searchText = "";
  this.orderStatus = "-- โปรดเลือกข้อมูล --";
},
getRedirectToPrintOrder(id) {
      window.open("/pages/wnf-order/"+id, "_blank");
},
  getNameWnfShop(id){

    if(this.washingShopList.length > 0){
      var list = this.washingShopList.filter(x => x.wnfShopId === id);
      if(list.length > 0) return list[0].wnfShopName
    }
    return "ไม่ระบุ"
  },

  async searching(){
    this.$vs.loading();
    await this.newload();
    this.$vs.loading.close();
  },

  async showDetail(tr){
    this.panelDisplay = true;
    var params = {
                wnfOrderId : tr.wnfOrderId
              };
    await this.$store.dispatch("wnfOrder/anOrder",params);


    this.$store.commit("wnfOrder/POP_PANEL",true);
    // this.$store.commit("wnfShop/SET_WNF_DETAIL",tr);
    // this.shopinfo = await this.loadShopInfo();

    (this.displayPopup)++;

    // console.log("this.panelDisplay >>> ",this.panelDisplay);
  },

  async remove(id){
    var item = {
      wnfShopId: id
    };
    this.$swal({
        title: "ต้องการลบข้อมูลใช้หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรูป"
      }).then(
        async result => {

          if (result.value) {
            await this.$store.dispatch("wnfShop/remove",item);
            await this.newload();

          }
        }
      );
  },

  async changeStatus(indextr , tr){


    // let newstatus =  !this.displayItems[indextr].status;

    var req = {
                  "wnfShopId": tr.wnfShopId,
                  "status": !tr.status
                }



    await this.$store.dispatch("wnfShop/changeStatus", req);

    this.$set(this.displayItems , indextr, this.displayItems[indextr]);

    await this.newload();
  },
  remainingText(maxText, theText){
      if(theText === undefined || theText === null) theText = "";
      this.blockText = theText;
      this.previousText
      var t = maxText - theText.length;
      if(t <= 0) return 0;
      else
        return t;
    },
    openSendSingleItem(item, action){

      this.washPanel = true;
      this.sendingItems = [];
      this.sendingItems.push(item);
      this.sendingAction = action;



    },

    openSendMultipleItem(action){


      this.sendingItems = [];
      this.sendingAction = action;
      let total = 0

      this.pdlist.forEach( x => {

        if( (this.sendingAction === 1 ||  this.sendingAction === 3 ) && (x.orderDetailStatus === "คืนชุดแล้ว" || x.orderDetailStatus === "" || x.orderDetailStatus === null || x.orderDetailStatus === undefined) )
        {
          this.sendingItems.push(x);
          total++;
        }

        if(  (this.sendingAction === 2) && (x.orderDetailStatus === "กำลังซักซ่อม") )
        {
          this.sendingItems.push(x);
          total++;
        }




      });

      if(total <= 0){
        this.$swal({
          icon: 'warning',
          title: 'ท่านยังไม่ได้เลือกสินค้า',
          text: "กรุณาเลือกสินค้า (สถานะ: รอดำเนินการ) อย่างน้อย 1 ชิ้น",
        });
      }else{
         this.washPanel = true;
         this.sendingAction = action;
      }



    },

    closeSendItem() {
        this.sendingReq = {};
        this.washPanel = false;
        this.sendingItems = [];
        this.washingDate = null;
        this.washingShop = null;
        this.infoNote = "";
        this.sendingAction = 0;
    },

    async sendingToWash(){

      //*** VALIDATE ***/

      if(  (this.sendingAction === 1) &&  ( this.washingDate === null ||  Object.entries(this.washingDate).length <= 0 ) ){
          this.$swal({
            icon: 'warning',
            title: 'กรุณาเลือกวันที่ในการส่งดำเนินการ',
          });

          return;
      }
      if(  (this.sendingAction === 1) &&  ( this.washingShop === null || this.washingShop === undefined ||  Object.entries(this.washingShop).length <= 0 ) ){
          this.$swal({
            icon: 'warning',
            title: 'กรุณาเลือกผู้ติดต่อ',
          });

          return;
      }

    if( this.sendingItems.length <= 0)
    {
      this.$swal({
            icon: 'warning',
            title: 'กรุณาเลือกสินค้าที่ต้องการส่งอย่างน้อย 1 สินค้า',
          });
    }



      //*** TRANSFORM ***/


      // console.log("washingShop >>> ",this.washingShop)


      // console.log("wnfOrderArray >>> ",wnfOrderArray)

      this.btnActive = !this.btnActive;
      // this.$vs.loading();


       let req = {

              };

      let wnfOrderArray = this.automapper(this.sendingItems , ["orderDetailId"]);

      //*** ส่งซัก */
      if(this.sendingAction === 1)
      {
        req = {
                  sendingDate: this.formatDate(this.washingDate.start),
                  receivingDate: this.formatDate(this.washingDate.end),
                  remark: this.infoNote,
                  branchId: this.getCurrentBranch.branchId,
                  wnfShopId: this.washingShop.wnfShopId,
                  wnfOrderDetails: wnfOrderArray
              };
        await this.$store.dispatch("washingList/sendToWash", req);
      }
      //*** รับชุดซักกลับ */
      else if(this.sendingAction === 2)
      {
        req = {
                wnfOrderId : this.sendingItems[0].wnfOrderId
              };
        await this.$store.dispatch("washingList/receiveFromWash", req);
        this.removeSelected();
      }
      //*** ไม่ส่งซัก */
      else if(this.sendingAction === 3){

        await this.$store.dispatch("washingList/notToSend", wnfOrderArray);
        this.removeSelected();
      }

      this.closeSendItem();

      this.btnActive = !this.btnActive;


      this.newload();

      // this.$vs.loading.close();

      // this.washingDate = null;
      // this.washingShop = null;
      // this.infoNote = "";


    },

    // clearSeachParams(){
    //   this.$store.dispatch('washingList/clearParm');
    //   this.newload();
    // },
    // clearParams(){
    //   this.$store.dispatch();
    // },
    checkSearchParam(){

      if(this.searchParam !== null && this.searchParam !== undefined)
        {
          if(Object.entries(this.searchParam).length > 0){
            return true;
          }
        }
      else
        return false;
    },
    removeSelected(){

        this.$store.dispatch("washingList/clearAll");
        this.selectedList = [];
        this.newnumber = 0;
        this.selectStatus = 0;

        this.displayItems.forEach(element => {
            element.selected = false;
        });
        // console.log('REMOVE CLEAR ALL ');
        // console.log('getter >>>> ',this.$store.getters["washingList/selected"]);


    },

    // ************** FOR SIDE BAR **************
    InStockValue(newvalue, newvalue_ind){
      console.log('Data saved and new value is >>> ',newvalue);
      console.log('Data saved and new index is >>> ',newvalue_ind);
      this.itemsInStock[newvalue_ind].inStock = newvalue;
      this.$set(this.itemsInStock , newvalue_ind ,this.itemsInStock[newvalue_ind] );


    },

    async SearchParameterValue(){

      console.log("this.searchParam" , this.searchParam);

      // active: false
      // category: "M"
      // color: "B"
      // hotItem: false
      // newArrival: false
      // onWeb: false
      // searchText: "hello"
      // size: null
      // texture: "PP"


      this.newload();



    },

    toggleFilterDataSidebar(val=false) {
      this.filterDataSidebar = val
    },
    filterData() {
      this.filterSidebarData  = {};
      // let data = Object.assign({}, pg);
      // this.sidebarData = data;
      // this.itemind = ind;
      (this.filterCleanFixkKey)++;

      this.toggleFilterDataSidebar(true)
    },


    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
    },




    createCatalog() {
      this.$router.push("/pages/catalog-page");
    },
    async startSearch() {
      // set page  = 1 every time that start to search
      this.currentx = 1;
      this.reload();
    },

    async addGroup(groupid, productid) {
      if (groupid === null || groupid === "" || groupid === undefined) {
        window.open("/backoffice/add-group/" + productid, "_blank");
      } else {
        window.open("/backoffice/add-group/" + groupid, "_blank");
      }
    },

    // async submitBorrow(item, index) {
    //   this.$vs.loading({ type: "radius" });
    //   // console.log(
    //   //   "submitBorrow start >>>>>>>>> ",
    //   //   this.formatDate(item.pickupDate),
    //   //   "endDate >>>>>> ",
    //   //   this.formatDate(item.returnDate)
    //   // );

    //   var theItem = {
    //     productItemId: item.productItemId,
    //     approve: 0,
    //     startDate: this.formatDate(this.calendarBorrow.start),
    //     endDate: this.formatDate(this.calendarBorrow.end),
    //     // fromBranch: this.$store.state.auth.mybranchid,
    //     fromBranch: this.branchrad,
    //     toBranch: this.branchRadio[index],
    //     qty: this.reservedq[index],
    //     updateBy: this.currentUser
    //   };
    //   var res = null;
    //   try {
    //     res = await this.$http.post(
    //       this.$store.state.apiURL + "/api/borrow",
    //       theItem,
    //       this.$store.state.auth.apiHeader
    //     );
    //   } catch (err) {
    //     res = null;
    //     setTimeout(() => {
    //       this.$vs.loading.close();
    //       this.noticeError("เกิดข้อผิดพลาดไม่สามารถส่งข้อมูลขอยืมชุดได้");
    //     }, 200);
    //   }
    //   if (res !== null) {
    //     setTimeout(() => {
    //       this.$vs.loading.close();
    //       this.noticeSuccess("ส่งข้อมูลเพื่อขอยืมชุดแล้ว");
    //       this.borrowPanelActive = false;
    //     }, 200);
    //   }
    // },
    // selection1_result(ind, value) {
    //   this.blist[ind].branchId = value;
    //   this.branchRadio[ind] = value;
    //   this.borValid = 0;
    // },
    // getCustomerName(orderid) {
    //   // console.log("getCustomerName >>>> ", orderid);

    //   var ord = this.orders.find(x => x.orderHeaderId === orderid);

    //   if (ord === null || ord === undefined) {
    //     return "";
    //   } else {
    //     return ord.customerName;
    //   }
    // },
    // removeZero(list) {
    //   var tmp = [];
    //   var i = 0;
    //   if (this.$acl.check("staff")) {
    //     for (i = 0; i < list.length; i++) {
    //       if (this.mybranchstock(list[i].productItemId) > 0) {
    //         tmp.push(list[i]);
    //       }
    //     }
    //   } else {
    //     for (i = 0; i < list.length; i++) {
    //       tmp.push(list[i]);
    //     }
    //   }

    //   return tmp;
    // },
    // seq(ind) {
    //   var seqnum = Number(ind);
    //   if (!isNaN(seqnum)) {
    //     return (seqnum + 1).toString() + " .";
    //   } else {
    //     return "0 .";
    //   }
    // },
    async getBranch(id) {
      var response = null;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch/" + id,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
        this.noticeError(error);
      }

      return await response.data;
    },

    mybranchstock(productId) {
      var st = this.thestock.find(
        x =>
          x.productItemId === productId &&
          x.branchId === this.$store.state.auth.mybranchid
      );
      if (st === null || st === undefined || st === "") {
        return 0;
      } else {
        return st.inStock;
      }
    },
    zoomimage(image) {
      this.zoomzoom = this.$store.state.imageStore + image;
      this.zoomPopupActive = true;
    },

    handleChangePage() {
      //  console.log("The user changed the page ");
    },

    checkBranch() {
      this.itemsInStock = [];
    },

    // resetlist() {
    //   this.itemsInStock = [];
    //   for (var j = 0; j < this.originalList.length; j++) {
    //     this.itemsInStock.push(this.originalList[j]);
    //   }
    // },
    // getBarcode(code) {
    //   if (
    //     code === "" ||
    //     code === null ||
    //     code === undefined ||
    //     code === "01WSW001S"
    //   ) {
    //     return "ไม่ได้บันทึก";
    //   } else {
    //     return code;
    //   }
    // },

    async reload() {
      this.btnActive = !this.btnActive;
      // this.$vs.loading({ type: "radius" });

      // LOAD ITEM
      try {
        // RELOAD STOCK
        var responseStock = await this.$http.get(
          this.$store.state.apiURL + "/api/stock",
          this.$store.state.auth.apiHeader
        );
        this.thestock = await responseStock.data;

        // RELOAD ITEMS

        var response = null;

        response = await this.$http.post(
          this.$store.state.apiURL + "/api/productitem/pagination",
          {
            T1: this.currentx,
            T2: this.pageitemSelected,
            T3: this.searchText
          },
          this.$store.state.auth.apiHeader
        );

        // console.log("RELOAD response >>> ", response);

        // response = await this.$http.get(
        //   this.$store.state.apiURL + "/api/productitem",

        //   this.$store.state.auth.apiHeader
        // );

        this.pageNumber = response.data.pageNumber;
      } catch (error) {
        response = null;
        this.$vs.loading.close();
      }

      if (response === null) {
        setTimeout(() => {
          // this.noticeError("ไม่สำเร็จ");

          this.$vs.loading.close();
        }, 200);
        this.btnActive = !this.btnActive;
      } else {
        //  console.log("ALREADY 200");
        this.itemsInStock = response.data.itemList;
        this.originalList = response.data.itemList;
        // if (this.$store.state.auth.permission === "staff") {
        //   this.itemsInStock = this.removeZero(response.data);
        //   this.originalList = this.removeZero(response.data);
        // }

        this.$vs.loading.close();

        // this.noticeSuccess("บันทึกเสร็จสิ้น");

        this.btnActive = !this.btnActive;
      }
      this.checkSelected();
    },

    async newload() {


      try {


        // RELOAD ITEMS

        console.log("this.orderStatus >>> ",this.orderStatus);

        // var response = null;
        var ordstatus = null
        if(this.orderStatus.includes(this.orderStatus) ){
          if(this.orderStatus === "-- โปรดเลือกข้อมูล --"){
            ordstatus = null;
          }

          else if (this.orderStatus === "กำลังดำเนินการ"){
            ordstatus = "กำลังซักซ่อม";
          }
          else if (this.orderStatus === "ไม่ดำเนินการ"){
            ordstatus = "ไม่ส่งซัก";
          }

          else{
            ordstatus = this.orderStatus;
          }
        }


        let params = {
          // page (required)
          "page": this.currentx,
          // pageSize (required)
          "pageSize": this.pageitemSelected,
          // search Text
          "searchText": this.searchText,
          // branch ID (required)
          "branchId" : this.getCurrentBranch.branchId, //"452971b-0e27-4b1c-a9a1-5f46d7c3eaa7"
        //   "sendingStartDate" : "",
        //   "sendingEndDate" : "",
        //   "receivingStartDate" : "",
        //   "receivingEndDate" : "",
        //   "wnfShopIds" : "",
          "orderStatus" : ordstatus,
        //   "colors" : "R"
        //   ,"size" : "S"
      }

      this.$vs.loading();
      await this.$store.dispatch("wnfOrder/fetchList",params);


      // response = this.theDisplay;
      this.displayItems = this.theDisplay.itemList;
      this.originalList = this.theDisplay.itemList;

      this.pageNumber = this.theDisplay.pageNumber;

      // this.checkSelected();

      } catch (error) {
        // response = null;
      }

      this.$vs.loading.close();



    },

    async removeItem(event, ind, item) {
      event.stopPropagation();
      this.removePopupActive = true;
      this.removeIndex = ind;
      this.removeCode = item.code;
      this.removeId = item.productItemId;
    },
    // async remove() {
    //   var resp = await this.$http.delete(
    //     this.$store.state.apiURL + "/api/productitem/" + this.removeId,
    //     this.$store.state.auth.apiHeader
    //   );
    //   // var resp = await productService.remove(this.$vs.notice, this.removeId);
    //   if (resp.status === 200) {
    //     this.noticeSuccess("ลบ " + this.removeCode + " สำเร็จ");
    //     this.itemsInStock.splice(this.removeIndex, 1);
    //     this.closeRemovePanel();
    //   } else {
    //     this.noticeSuccess("มีข้อผิดพลาด");
    //   }
    // },
    closeRemovePanel() {
      this.removePopupActive = false;
      this.removeIndex = -1;
      this.removeCode = "";
      this.removeId = "";
    },
    getFullpath(mainImage) {
      if (
        mainImage === undefined ||
        mainImage === null ||
        mainImage === "" ||
        mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + mainImage;
      }
    },
    async changeState(ind) {
      // console.log(
      //   "ind >>> ",
      //   ind,
      //   " this.itemsInStock[ind].state >>> ",
      //   this.itemsInStock[ind].state
      // );

      await this.put(
        "/api/productitem",
        this.itemsInStock[ind],
        this.itemsInStock[ind].productItemId
      );

      // productService.update(
      //   this.itemsInStock[ind],
      //   this.itemsInStock[ind].productItemId
      // );
    },
    getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    getColorName(codename) {
      var c = this.colors.find(x => x.code === codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },
    getSizeName(codename) {
      var c = this.sizes.find(x => x.code === codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },
    async newItem() {
      // console.log("NEW ITEM");
      this.$vs.loading({ type: "radius" });
      try {
        //   var response = await this.$http.post(
        //     this.$store.state.apiURL + "/api/productgroup",
        var item = {
          name: "New Item",
          code: "",
          category: "",
          texture: "",
          size: "",
          yearth: 1,
          gender: "",
          itemAmount: 1,
          rentalPrice: 0,
          bail: 0,
          itemStatus: "เปิด",
          webCategory: "",
          webHotItem: "ไม่ใช่",
          webItem: "n",
          itemCode3Digit: "000",
          color: "",
          newRecord: true,
          temporary: false,
          state: true,
          updateBy: this.currentUser,
          createBy: this.currentUser
        };
        var res = await this.$store.dispatch("productGroup/create", item);
        // console.log("res >>>>>>>>>>>> ", res);

        if (res) {
          await this.$router.push("/backoffice/additem/" + res.productGroupId);
        }
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        this.noticeError(error);
        return;
      }
    },
    editItem(event, productItemId) {
      event.stopPropagation();
      window.open("/backoffice/updateitem/" + productItemId, "_blank");
      // this.$router.push("/backoffice/create-item/" + productItemId);
    },

    displayStatus(ordername){
      if(ordername === "" || ordername === null || ordername === undefined || ordername === "คืนชุดแล้ว" || ordername === "รอส่งซักซ่อม")
      {
        return "รอดำเนินการ"
      }
      if(ordername === "กำลังซักซ่อม")
      {
        return "กำลังดำเนินการ"
      }

      if(ordername === "ไม่ส่งซัก")
      {
        return "ไม่ดำเนินการ"
      }
    },
    displayStatusClass(ordername){
      if(ordername === "" || ordername === null || ordername === undefined || ordername === "คืนชุดแล้ว" || ordername === "รอส่งซักซ่อม")
      {
        return "label-btn-grey"
      }
      if(ordername === "กำลังซักซ่อม")
      {
        return "label-btn-yellow"
      }
    },

    onDateSelected: function(daterange) {
      this.selectedDate = daterange;
    },
    // FOR PRINT CARD



    getRedirectLink() {
      return "/backoffice/create-item/" + this.panelId;
    },
    getRedirect(id) {
      return "/backoffice/create-item/" + id;
    },

    resetpage() {
      this.lastPage = 0;
    },

    selectAllOrders: function(event) {
      event.stopPropagation();

      var cc = document.getElementsByClassName("is-current");
      var page_th = cc[0].getElementsByTagName("span")[0].innerText;
      var serow = document.querySelectorAll("input[data-id]");

      var codelist = [];

      for (var n = 0; n < serow.length; n++) {
        codelist.push(serow[n].getAttribute("data-id"));
      }

      var page_th_num = Number(page_th);
      if (isNaN(page_th_num)) {
        page_th_num = 1;
      }
      // console.log(" trow >>>>>>>>>>>>>>>>>>>>>>>>> ", trow[0].outerHTML);

      if (this.lastPage === 0) {
        //*** เข้ามาครั้งแรก
        this.lastPage = page_th_num;
      } else {
        //*** เปลี่ยนหน้าใหม่
        if (this.lastPage !== page_th_num) {
          this.selectStatus = 0;
          this.selectAll === false;
          this.lastPage = page_th_num;
          this.selectedList = [];
          for (ind = 0; ind < this.itemsInStock.length; ind++) {
            this.itemsInStock[ind].selected = false;
          }
        }
      }

      // ************************************************************************
      var ind = 0;

      // ************************************************************************
      //  คำนวณจำนวนที่ต้องเลือก
      // ************************************************************************


      // ************************************************************************

      var k = 0;
      var theind = -1;
      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0) {
        //  console.log("expected >>> 0");
        this.selectedList = [];

        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = true;
        //   this.selectedList.push(this.itemsInStock[ind]);
        // }

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          //  console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = true;
            this.selectedList.push(this.itemsInStock[theind]);
          }
        }

        this.selectStatus = 1;
        //  console.log("go out with >>> 1");
      } else if (this.selectStatus === 2) {
        //  console.log("expected >>> 2");
        // this.selectedList = [];
        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = true;
        //   this.selectedList.push(this.itemsInStock[ind]);
        // }

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          //  console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = true;
            this.selectedList.push(this.itemsInStock[theind]);
          }
        }
        this.selectStatus = 1;
        //  console.log("go out with >>> 1");
      } else if (this.selectStatus === 1) {
        //  console.log("expected >>> 1");
        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = false;
        // }
        // this.selectedList = [];

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          //  console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = false;
          }
        }

        this.selectedList = [];

        this.selectStatus = 0;
        //  console.log("go out with >>> 0");
      }
      this.fromSelectAll = true;
      // ************************************************************************
      // **  BEGIN : OLD CODE
      // ************************************************************************

      // if (this.selectAll === false && this.selectStatus === 1) {
      //   this.selectAll = true;
      // }
      // if (this.selectStatus === 0 || this.selectStatus === 2) {
      //   // console.log("expected >>> 0 or 2");
      //   this.selectedList = [];
      //   for (ind = 0; ind < this.itemsInStock.length; ind++) {
      //     this.itemsInStock[ind].selected = true;
      //     this.selectedList.push(this.itemsInStock[ind]);
      //   }
      //   this.selectStatus = 1;
      // } else if (this.selectStatus === 1) {
      //   // console.log("expected >>> 1");
      //   for (ind = 0; ind < this.itemsInStock.length; ind++) {
      //     this.itemsInStock[ind].selected = false;
      //   }
      //   this.selectedList = [];
      //   this.selectStatus = 0;
      // }
      // this.fromSelectAll = true;

      // ************************************************************************
      // **  END : OLD CODE
      // ************************************************************************
    }

    // formatPrice(value) {
    // let val = (value / 1).toFixed(2).replace(",", ".");
    // console.log(value);
    // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
  },

  components: {
    FilterCleanFixSidebar,
    Multiselect,
    BEmbed,
    Prism,
    Datepicker,
    // VueRangedatePicker,
    // VueHotelDatepicker,
    "v-select": vSelect,
    // HotelDatePicker,
    Selection1,
    CleanFixShopPanel,
    CleanFixPopupDisplayOrder

  },
  watch: {

    async orderStatus(){
      await this.newload();
    },

    basket: {
      async handler(){
        await this.newload();
      },
      deep: true
    },
    currentx: function() {
      this.newload();
    },
    pageitemSelected: function() {
      this.newload();
    },


    async getCurrentBranch(){
      console.log("getCurrentBranch >>> ",this.getCurrentBranch);
      await this.newload();
      // this.searchBtn();
      this.branchrad = this.getCurrentBranch.branchId;
      // console.log('curent brach changed !!! >>> ',value.branchName);

    },


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    assignItems: {
      handler() {
        // var assignedItemsSum = 0;
        // for (var i = 0; i < this.assignItems.length; i++) {
        //   assignedItemsSum = assignedItemsSum + this.assignItems[i].amount;
        // }
        // this.remainingItems = this.currentAmountInstock - assignedItemsSum;
        // console.log("WHEN CHANGE : Item In Stock  >>> ", this.itemsInStock);
      },
      deep: true
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  }
};
</script>

<style>

.vs-dropdown--menu{
  width: 170px;
}

.multiselect__content-wrapper .multiselect-leave-active{
  z-index: 99999 !important;
}

/* .vs__search{
  display: none !important;
} */
.clear-btn:hover{
   text-decoration: underline;
}
/* .vs-pagination--nav {
  display: none;
} */

.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.checkqueuelist {
  margin-top: 0;
}
.input-date {
  width: 100% !important;
}

.dropdown-toggle .clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none;
}
.selected-tag {
  font-size: 12px;
}

/* VUE HOTEL DATEPICKUP */
.previous-arrow .offset-2 .disabled {
  display: block !important;
}

/* .calendar {
  position: relative !important;
} */

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
  z-index: 999 !important;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

.zoomimage {
  width: 400px;
}
.datepicker__tooltip {
  display: none !important;
}

.demo-alignment {
  vertical-align: middle;
}

li {
  margin: 0 !important;
}

@media only screen and (max-width: 578px) {
  #main-background {
    width: 100% !important;
    padding: 0 !important;
  }
  #controller-panel {
    width: 91% !important;
  }
}
</style>
